<script setup lang="ts">
import {
  breakpointsTailwind,
  useBreakpoints,
  useElementBounding,
  useEventListener,
  useStorage,
} from '@vueuse/core';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import CloseIcon from '@/components/base/assets/CloseIcon.vue';
import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import UnstyledButton from '@/components/base/UnstyledButton.vue';
import SearchContent from '@/components/layout/header/search/SearchContent.vue';
import { useRouterLinks } from '@/composables/navigation/useRouterLinks';
import { useLargeDesktopCardGrid } from '@/composables/useLargeDesktopCardGrid';
import { useSearch } from '@/composables/useSearch';
import { useOverlay } from '@/stores/overlay';

const router = useRouter();
const route = useRoute();

const mainRef = ref<HTMLElement>();
const searchFormRef = ref<HTMLElement>();
const searchInputRef = ref<HTMLElement>();

const isOpen = defineModel<boolean>('isOpen', { required: true });
const query = defineModel<string>('query', { required: true });

const recentSearches = useStorage<string[]>('recentSearches', []);

const isMobile = useBreakpoints(breakpointsTailwind).smaller('lg');
const { height: searchFormHeight } = useElementBounding(searchFormRef);
const { activate, deactivate } = useFocusTrap(mainRef);
const overlay = useOverlay();

const shouldDisplay = computed(() => isOpen.value && isMobile.value);

const addRecentSearch = (entry: string) => {
  const existingSearches = recentSearches.value.filter((search) => search !== entry);
  recentSearches.value = [entry, ...existingSearches.slice(0, 4)];
};

const handleClose = () => {
  isOpen.value = false;
};

const handleSearch = async (search: string) => {
  await useLargeDesktopCardGrid().loadDyFlags();
  await useSearch(router).handleSearch(search);
  if (search) addRecentSearch(search);
};

const handleClearSearch = () => {
  query.value = '';
  searchInputRef.value?.focus();
};

const handleOnsiteOfferPopup = () => {
  deactivate();
};

watch(() => route?.path, handleClose);

onMounted(() => {
  watch(shouldDisplay, async (open) => {
    if (open) {
      handleClearSearch();
      window.Kustomer?.stop();
      overlay.show();
      await nextTick();
      activate();
      useEventListener(document, 'onsiteOfferPopup', handleOnsiteOfferPopup);
    } else {
      window.Kustomer?.start();
      overlay.hide();
      deactivate();
      isOpen.value = false;
    }
  });
});

onBeforeUnmount(() => {
  window.Kustomer?.start();
  overlay.hide();
  deactivate();
  isOpen.value = false;
});

useRouterLinks(mainRef, router);
</script>

<template>
  <div
    v-show="shouldDisplay"
    aria-label="Search"
    aria-modal="true"
    class="fixed inset-0 z-50 flex flex-col overflow-y-auto"
    data-test="header-search-modal"
    role="dialog"
    ref="mainRef"
  >
    <div
      class="fixed inset-x-0 z-10 flex gap-3 p-3 bg-white border-b border-solid border-neutral-300"
      ref="searchFormRef"
    >
      <form
        class="relative w-full"
        data-test="header-search-form"
        ref="searchFormRef"
        role="search"
        @submit.prevent="handleSearch(query)"
      >
        <input
          v-model="query"
          aria-label="Search"
          autocapitalize="off"
          autocomplete="off"
          autocorrect="off"
          class="w-full h-10 px-4 text-base border border-solid rounded-full bg-nuts-stone-100 border-neutral-300 focus:border-black focus:outline-none focus:border-2"
          data-test="header-search-input"
          placeholder="Search for a product"
          ref="searchInputRef"
          spellcheck="false"
          type="search"
        />
        <div class="absolute flex items-center gap-1 right-1 top-1 bottom-1">
          <transition name="fade" :duration="100">
            <UnstyledButton
              v-if="query.length"
              aria-label="Clear search"
              class="flex p-1 rounded-full hover:bg-white"
              data-test="clear-search-query-button"
              @click="handleClearSearch"
            >
              <CloseIcon :size="16" />
            </UnstyledButton>
          </transition>
          <UnstyledButton
            class="h-full px-4 rounded-full bg-nuts-amber-400"
            data-test="header-search-button"
            type="submit"
          >
            <SmallBodyText class="font-semibold leading-3 text-nuts-neutral-950">
              Search
            </SmallBodyText>
          </UnstyledButton>
        </div>
      </form>
      <UnstyledButton type="button" data-test="header-cancel-button" @click="handleClose">
        <BaseBodyText>Close</BaseBodyText>
      </UnstyledButton>
    </div>
    <!-- Overlay -->
    <div class="absolute inset-0 z-0 bg-black opacity-40 touch-none" @click="handleClose" />
    <!-- Modal container -->
    <div
      class="absolute inset-x-0 bottom-0 flex flex-col overflow-hidden opacity-100 pointer-events-none z-1 search-form-offset"
      :class="{ 'bg-white': query }"
    >
      <div
        class="max-h-full overflow-y-auto border-solid pointer-events-auto isolate overscroll-contain touch-pan-y touch-pan-x"
      >
        <SearchContent v-model:recentSearches="recentSearches" :query @search="handleSearch" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.search-form-offset {
  top: v-bind('`${searchFormHeight}px`');
}
</style>

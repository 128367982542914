import { context, trace } from '@opentelemetry/api';
import { defineStore } from 'pinia';
import { ref, shallowReactive } from 'vue';

export interface FeatureFlags extends Record<string, boolean | undefined> {
  adSurvey?: boolean;
  autoDeliveryUpsell?: boolean;
  disableReferral?: boolean;
  discountCodeForm?: boolean;
  expressCheckout?: boolean;
  giftContentList?: boolean;
  googleOneTapHomepage?: boolean;
  googleOneTapPDP?: boolean;
  largeProductCardGrid?: boolean;
  layoutRebrandingChangesV1?: boolean;
  leaveAReview?: boolean;
  mobileHeaderSearchV2?: boolean;
  oneClickCheckout?: boolean;
  pdpOneLinerFlavor?: boolean;
  pinGreetingCards?: boolean;
  showAddOn?: boolean;
  separateFilterSortPLP?: boolean;
  separateFilterSortSearch?: boolean;
  singleLineVariantSelection?: boolean;
  sizeGuide?: boolean;
  strikethroughPricing?: boolean;
  supportDefaultAddress?: boolean;
}

export const layers = ['local', 'session', 'site', 'visitor'] as const;
export type Layer = (typeof layers)[number];

export type FlagsByLayer = Record<Layer, FeatureFlags>;

export const useFeatureFlags = defineStore('featureFlags', () => {
  const flagsByLayer = ref<FlagsByLayer>({
    local: {},
    session: {},
    site: {},
    visitor: {},
  });

  const flags = shallowReactive(
    new Proxy<FeatureFlags>(
      {},
      {
        get: (target, property) => {
          const flagName = property.toString();
          const flagValue = target[flagName];
          if (flagName.substring(0, 2) !== '__' && flagName.substring(0, 7) !== 'Symbol(') {
            trace.getSpan(context.active())?.addEvent('feature_flag', {
              'feature_flag.key': flagName,
              'feature_flag.provider_name': 'nuts.com',
              'feature_flag.variant': (flagValue ?? 'null').toString(),
            });
          }
          return flagValue;
        },
      },
    ),
  );

  return {
    flags,
    flagsByLayer,
  };
});

<script setup lang="ts">
import { breakpointsTailwind, onClickOutside, useBreakpoints } from '@vueuse/core';
import { computed, Directive, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import UnstyledButton from '@/components/base/UnstyledButton.vue';
import StaticSearchSuggestions from '@/components/layout/header/search/StaticSearchSuggestions.vue';
import SearchSuggestionsPopover from '@/components/layout/header/SearchSuggestionsPopover.vue';
import { useLargeDesktopCardGrid } from '@/composables/useLargeDesktopCardGrid';
import { useSearch } from '@/composables/useSearch';
import { useState } from '@/composables/useState';
import { gtag } from '@/utils/analytics';
import { adaptMultipleQueryValues } from '@/utils/search/createAlgoliaRouter';

const props = defineProps<{ autofocus?: boolean }>();

const route = useRoute();
const router = useRouter();

const isDesktop = useBreakpoints(breakpointsTailwind).greater('md');

const { query } = useState('synchronizedSearchBar', () => ({ query: '' }));
query.value = route?.query.query ? adaptMultipleQueryValues(route.query) : '';

const vFocus: Directive = {
  mounted: (el: HTMLElement) => {
    if (props.autofocus) el.focus();
  },
};

const showSearchSuggestions = ref(false);

const searchInputRef = ref<HTMLElement>();
const searchFormRef = ref<HTMLElement>();

const isSearchPage = computed(() => route?.name === 'Search');

const handleSearch = async () => {
  gtag('event', 'Header Search Submission', {
    header_type: 'Redesign V1',
  });

  await useLargeDesktopCardGrid().loadDyFlags();
  await useSearch(router).handleSearch(query, !showSearchSuggestions.value);
};

const onSuggestionsClosed = (setFocus?: boolean) => {
  showSearchSuggestions.value = false;
  if (setFocus) searchInputRef.value?.focus();
};

watch(isSearchPage, (isSearch) => {
  if (!isSearch) query.value = '';
});

onMounted(() => {
  onClickOutside(searchFormRef.value, () => onSuggestionsClosed());
});
</script>

<template>
  <div class="flex flex-col items-center">
    <form
      class="relative w-full"
      ref="searchFormRef"
      role="search"
      @submit.prevent="handleSearch"
      data-test="search-bar"
    >
      <input
        v-focus
        v-model="query"
        aria-label="Search"
        autocapitalize="off"
        autocomplete="off"
        autocorrect="off"
        class="w-full h-10 px-4 text-base border border-solid rounded-full bg-nuts-stone-100 border-neutral-300"
        placeholder="Search for a product"
        data-test="search-input"
        ref="searchInputRef"
        spellcheck="false"
        type="search"
      />
      <UnstyledButton
        class="absolute px-4 rounded-full right-1 top-1 bottom-1 bg-nuts-amber-400 hover:bg-amber-400"
        data-test="search-button-redesignv1"
        type="submit"
      >
        <SmallBodyText
          class="font-semibold leading-3 text-nuts-neutral-950"
          data-test="search-button-header"
        >
          Search
        </SmallBodyText>
      </UnstyledButton>
      <SearchSuggestionsPopover
        v-if="!isSearchPage && isDesktop"
        v-model="showSearchSuggestions"
        :query="query"
        @dismiss="onSuggestionsClosed(true)"
      />
    </form>
    <StaticSearchSuggestions class="mt-1" />
  </div>
</template>

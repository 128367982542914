/* eslint-disable import/prefer-default-export */
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';

import { useFeatureFlags } from '@/composables/useFeatureFlags';

export function useLargeDesktopCardGrid() {
  async function loadDyFlags() {
    const isLargeDesktop = useBreakpoints(breakpointsTailwind).isGreater('xl');

    if (isLargeDesktop)
      await useFeatureFlags().loadDyFlags('[A/B Test] Search and PLP Large Desktop Card Grid', [
        'largeProductCardGrid',
      ]);
  }

  return { loadDyFlags };
}
